
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Modal } from 'ant-design-vue'
import {
  commentRecords,
  deleteCommentRecords,
  commentTypeRecords,
} from '@/api/common'
import any from 'vue2-datepicker'
@Component
export default class Commit extends Vue {
  //计划id
  @Prop({ type: String, default: '', required: true })
  planId!: string
  //计划类型 1年计划 2月计划 3周计划 4日计划
  @Prop({ type: Number, default: '', required: true })
  planType!: number
  //评论类型 1 点赞 2评论
  type: number = 1
  //评论列表
  @Watch('planId')
  handleValueChange2(val: any) {
    this.getCommitList()
  }
  private content: string = ''
  private zanId: string = ''
  commitRecordList: any = []
  get userId() {
    return this.$store.state.user.userInfo.id
  }
  mounted() {
    console.log(this.$store.state.user.userInfo)
    this.getCommitList()
  }
  //根据返回值判断是否已经点赞，并且获取到当前用户点赞信息的id
  public get isZan(): any {
    if (this.commitRecordList) {
      console.log(this.$store.state.user)
      console.log('当前用户的id',this.$store.state.user.userInfo.id)
      return this.commitRecordList.some((item: any) => {
        console.log(item.userId,this.userId)
        if (item.userId === this.userId) {
          this.zanId = item.id
          return true
        }
      })
    } else {
      return false
    }
  }
  //点赞
  async publishCommit(i: number) {
    this.type = i
    const commitData: any = {
      planType: this.planType,
      planId: this.planId,
      type: this.type,
      content: this.content,
    }
    const [err, data] = await commentRecords(commitData)
    if (!err) {
      if (this.type === 1) {
        this.$message.success('点赞成功！')
        this.getCommitList()
      }
    }
  }
  //获取点赞列表
  async getCommitList() {
    const [err, data] = await commentTypeRecords(this.planId)
    if (!err) {
      this.commitRecordList = data
      //this.commitRecordList.push(...data)
    } else {
    }
  }
  //取消点赞
  async deleteZan(id: string) {
    const [err, data] = await deleteCommentRecords(id)
    if (!err) {
      this.$message.success('取消点赞！')
      this.getCommitList()
    } else {
    }
  }
}
