
































































































































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import { Modal } from 'ant-design-vue'
import {
  commentRecords,
  deleteCommentRecords,
  commentRecordsList,
  editComment,
} from '@/api/common'
import any from 'vue2-datepicker'
@Component
export default class Commit extends Vue {
  //计划id
  @Prop({ type: String, default: '', required: true })
  planId!: string
  //计划类型 1年计划 2月计划 3周计划 4日计划
  @Prop({ type: Number, default: '', required: true })
  planType!: number
  //评论类型 1 点赞 2评论
  type: number = 1
  //是否可自己评论
  @Prop({ type: Boolean, default: true, required: false })
  isCommit!:boolean
  //
  @Ref('myReplyContainer')
  myReplyContainer: any
  @Ref('myCommentContainer')
  myCommentContainer: any

  commitRecordList: any = []
  @Watch('commitRecordList')
  handleValueChange(val: any) {
    this.setStyle()
  }
  @Watch('planId')
  handleValueChange2(val: any) {
    this.getCommitList()
    this.content = ''
  }
  private content: string = ''
  private replyContent: string = ''
  private isFocus: boolean = true
  private currentEditId = ''
  private currentReplyId = ''
  //文本框字数限制
  private limitNumber:Number =10000 
  mounted() {
    this.getCommitList()
    document.addEventListener('click', this.documentHandler)
  }
  get userId() {
    return this.$store.state.user.userInfo.id
  }
  async documentHandler() {
    if (
      document.getElementsByClassName('page-text-area')[1] ===
      document.activeElement 
    ) {
    } else {
      await this.getCommitList()
      this.commitRecordList.forEach((i: any) => {
        i.isReply = false
        i.isEdit = false
      })
      this.replyContent = ''
      
    }
  }
  //打开回复框
  openReply(id: string) {
    this.currentEditId = id
    this.commitRecordList.forEach((i: any) => {
      i.isReply = false
    })
    this.commitRecordList.forEach((i: any) => {
      if (i.id === id) {
        i.isReply = true
      }
    })
    this.$nextTick(() => {
      this.myReplyContainer[0].focus()
    })
  }
  //下拉选中
  onClick(e: any, item: any) {
    if (e.key === 'item_0') {
      this.editCommit(item.id)
    } else if (e.key === 'item_1') {
      this.deleteCommit(item.id)
    }
  }
  //设置样式
  setStyle() {
    this.$nextTick(function () {
      var elements: any = document.getElementsByClassName('item-right')
      if (elements && elements.length > 0) {
        for (var i = 0; i < elements.length; i++) {
          elements[i].style.borderBottom = '1px solid #e3e5ec'
        }
      }

      if (elements && elements.length > 0) {
        elements[elements.length - 1].style.border = 'none'
      }
    })
  }
  //设置时间规则
  ruleTime(time:any) {
    const today  = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    const date = today.getDate()
    const timeYear = new Date(time).getFullYear()
    const timeMonth = new Date(time).getMonth() + 1
    const timeDate = new Date(time).getDate()
    // console.log('现在的时间',year,month,date)
    // console.log('传入的时间',timeYear,timeMonth,timeDate)
    if(year === timeYear && month ===timeMonth && date ===timeDate) {
      return time.slice(10, 16)
    } else if(year === timeYear && month ===timeMonth &&  timeDate ===(date -1)) {
      return '昨天'+time.slice(10, 16)
    } else if(year === timeYear) {
      return time.slice(5, 16)
    } else {
      return time
    }
  }
  //发表评论
  async publishCommit(id: string, content: any) {
    this.type = 2
    let commitData = {}
    if (id) {
      commitData = {
        planType: this.planType,
        planId: this.planId,
        type: this.type,
        content: content,
        replyId: id,
      }
    } else {
      commitData = {
        planType: this.planType,
        planId: this.planId,
        type: this.type,
        content: this.content,
      }
    }
    const [err, data] = await commentRecords(commitData)
    if (!err) {
      this.$message.success('评论成功！')
      this.content = ''
      this.replyContent = ''
      this.getCommitList()
    }
  }
  //获取评论列表
  async getCommitList() {
    const [err, data] = await commentRecordsList(this.planId)
    if (!err) {
      this.commitRecordList = data
      // this.commitRecordList.forEach((item:any)=> {
      //   console.log(JSON.stringify(item.content) )
      // })
      this.commitRecordList.forEach((item: any) => {
        this.$set(item, 'isEdit', false)
        this.$set(item, 'isReply', false)
        //item.isEdit = false
      })
    }
  }
  //修改评论
  async editComment(id: any, content: any) {
    this.type = 2
    const commitData: any = {
      planType: this.planType,
      planId: this.planId,
      type: this.type,
      content: content,
    }
    const [err, data] = await editComment(id, commitData)
    if (!err) {
      this.$message.success('修改成功！')
      this.getCommitList()
    }
  }
  //回复评论
  checkCommit(content: any, id: string) {
    this.currentReplyId = id
    this.commitRecordList.forEach((i: any) => {
      i.isEdit = false
      i.isReply = false
    })
    this.publishCommit(this.currentReplyId, content)
  }
  //删除评论
  deleteCommit(id: string) {
    Modal.confirm({
      title: '提示',
      content: '确定要删除吗？',
      onOk: () => {
        this.deleteCurrentCommit(id)
      },
    })
  }
  async deleteCurrentCommit(id: string) {
    const [err, data] = await deleteCommentRecords(id)
    if (!err) {
      this.$message.success('删除成功！')
      this.getCommitList()
    } else {
    }
  }
  //编辑评论
  editCommit(id: string) {
    this.currentEditId = id
    this.commitRecordList.forEach((i: any) => {
      i.isEdit = false
    })
    this.commitRecordList.forEach((i: any) => {
      if (i.id === id) {
        i.isEdit = true
      }
    })
    this.$nextTick(() => {
      this.myCommentContainer[0].focus()
    })
  }
}
